<!-- update code -->
<template>
  <v-card class="pa-0">
    
    <v-toolbar min-width="100%" dark color="primary" class="d-flex align-end">
      <v-card-title>Show Attendance</v-card-title>
    </v-toolbar>
    <v-data-table :headers="headers" :items="filteredData" >
      <template #[`item.access_id`]="{ item }">{{ item.access_id }}</template>
      <template #[`item.username`]="{ item }">{{
        item.attend_user_name
      }}</template>

  <template #[`item.log_time`]="{ item }">
    {{ item.log_time && new Date(item.log_time).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: '2-digit' }).split(' ').join('-').replace(',', '').replace(/(\w{3})-(\d{2})-(\d{2})/, "$2-$1-$3") || '' }}
  </template>

      <template #[`item.start`]="{ item }">
        {{ formatTime(item.start_time) }}
      </template>
      <template #[`item.end`]="{ item }">
        {{ formatTime(item.end_time) }}
      </template>

      <template #[`item.room_name`]="{ item }">{{ item.room_name }}</template>
<template #[`item.early`]="{ item }">
        {{
          item.start_time && item.first_check_in
            ? parseInt(getTimeInMinutes(item.start_time)) -
                convertToMinutes(
                  new Date(item.first_check_in).toLocaleTimeString()
                ) >=
              0
              ? "P"
              : Math.floor(
                  Math.abs(
                    parseInt(getTimeInMinutes(item.start_time)) -
                      convertToMinutes(
                        new Date(item.first_check_in).toLocaleTimeString()
                      )
                  ) / 60
                ) +
                " hr " +
                (Math.abs(
                  parseInt(getTimeInMinutes(item.start_time)) -
                    convertToMinutes(
                      new Date(item.first_check_in).toLocaleTimeString()
                    )
                ) %
                  60) +
                " min"
            : ""
        }}
   
</template>

<template #[`item.late`]="{ item }">
  {{
    item.end_time && item.last_check_out
      ? parseInt(item.end_time ? getTimeInMinutes(item.end_time) : "") -
          convertToMinutes(
            new Date(item.last_check_out).toLocaleTimeString()
          ) >=
        0
        ? Math.abs(
            parseInt(item.end_time ? getTimeInMinutes(item.end_time) : "") -
              convertToMinutes(
                new Date(item.last_check_out).toLocaleTimeString()
              )
          ) < 60
        ? Math.abs(
            parseInt(item.end_time ? getTimeInMinutes(item.end_time) : "") -
              convertToMinutes(
                new Date(item.last_check_out).toLocaleTimeString()
              )
          ) + " min"
        : Math.floor(
            Math.abs(
              parseInt(item.end_time ? getTimeInMinutes(item.end_time) : "") -
                convertToMinutes(
                  new Date(item.last_check_out).toLocaleTimeString()
                )
            ) / 60
          ) +
          " hr " +
          (Math.abs(
            parseInt(item.end_time ? getTimeInMinutes(item.end_time) : "") -
              convertToMinutes(
                new Date(item.last_check_out).toLocaleTimeString()
              )
          ) %
            60) +
          " min"
        : "P"
      : "-"
  }}
</template>
    
    
    </v-data-table>

  </v-card>
</template>

<script>

export default {
  // components: { DataTableSSR },
  data() {
    return {
      checkedIn: false,
      checkedOut: false,
      // checkInTime: null,
      checkOutTime: null,
      headers: [
        { text: "Access ID", value: "access_id" },

        { text: "Date", value: "log_time" },
        { text: "Name", value: "username" },
        { text: "Class", value: "room_name" },
        { text: "Start Time", value: "start" },
        { text: "End Time", value: "end" },
        // { text: "checkin Time", value: "first_check_in" },
        // { text: "checkout Time", value: "last_check_out" },
        { text: "Late In(hh:mm)", value: "early" },
        { text: "Early Out(hh:mm)", value: "late" },
      ],
      checkData:[],
    };
  },
  computed: {
  filteredData() {
    return this.checkData.filter(row => {
      if (typeof row === 'object' && row !== null) { 
        const values = Object.values(row); 
        return values.some(val => val !== null && val !== 0); 
      }
      return false; 
    });
  },
},

mounted(){
  this.loadCheck()
},
  methods: {
    loadCheck(){
      this.$api.get("/check_results/").then((r)=>{this.checkData=r.data.results})
    },
    convertToMinutes(timeString) {
      const [hours, minutes] = timeString.split(":").map(Number);
      return hours * 60 + minutes;
    },
    getTimeInMinutes(timeString) {
      const [hours, minutes] = timeString.split(":").map(Number);
      const date = new Date();
      date.setHours(hours, minutes);
      return date.getHours() * 60 + date.getMinutes();
    },
    
    formatTime(timeString) {
      if (!timeString) {
        return "";
      }

      const [hours, minutes] = timeString.split(":");
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const period = hours < 12 ? "AM" : "PM";

      return `${formattedHours}:${minutes} ${period}`;
    },
  },
};
</script>

<style></style>
